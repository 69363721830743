export default async function({ app, store, $sentry }) {
  try {
    if (!app.$auth.loggedIn) return

    const futures = [
      store.dispatch('fetchProjects')
    ]
    await Promise.all(futures)

  } catch (err) {
    $sentry.captureException(err)
  }
}

