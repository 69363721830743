import { io } from 'socket.io-client'

export default ({ $auth, $config }, inject) => {
  if (!$auth.loggedIn) return

  const token = $auth.strategy.token.get()
  const socket = io($config.pythonServiceUrl, {
    auth: { token },
    transports: ['websocket']
  })

  // Inject the socket instance into the app if it exists
  inject('socket', socket)
}
