export default {
  "code": "de",
  "title": "Deutsch",
  "flag": "🇩🇪",
  "meta": {
    "index": {
      "title": "Bessere Hausarbeiten.\n10-mal schneller mit KI.",
      "description": "Ohne Hesse: Du starrst stundenlang auf eine leere Seite.\nMit Hesse: Du generierst großartige Texte auf Knopfdruck."
    },
    "profile": {
      "title": "Profileinstellungen",
      "description": "Steuere deine Profileinstellungen ganz einfach"
    }
  },
  "buttons": {
    "close": "Schließen",
    "upgrade": "upgraden",
    "get_more_words": "Jetzt mehr Wörter holen",
    "login": "login",
    "logout": "logout",
    "cancel": "Abbrechen",
    "yes": "Ja",
    "no": "Nein",
    "tryout": "ausprobieren",
    "add": "hinzufügen",
    "report": "melden",
    "save": "speichern",
    "edit": "bearbeiten",
    "copy": "kopieren",
    "delete": "löschen",
    "generate": "generieren",
    "signup": "registrieren",
    "continue": "weiter",
    "submit": "senden",
    "my_sources": "Meine Quellen",
    "my_projects": "Meine Projekte",
    "add_source": "Quelle hinzufügen",
    "select_source": "Quelle auswählen",
    "my_documents": "Meine Dokumente",
    "new_document": "Neues Dokument",
    "back": "Zurück",
    "local_document": {
      "title": "Ein Dokument von meinem Computer",
      "type": "Dokument hochladen"
    },
    "web_document": {
      "title": "Du hast einen Link zu einem Dokument?",
      "type": "Web-Link/Dokument"
    }
  },
  "labels": {
    "missing_subscription": "Dieses Feature ist nur für Abonnenten verfügbar.",
    "booked": "dein aktueller Plan",
    "account": "Account",
    "subscription": "Abonnement",
    "name": "Name",
    "email": "E-Mail",
    "limit": "Limit",
    "settings": "Einstellungen",
    "words": "Wörter",
    "usage": "Nutzung",
    "extra_words": "inkl. {words} extra Wörter",
    "show_all_applications": "Alle Anwendungen anzeigen",
    "show_all": "Alle anzeigen",
    "start_page": "Zur Startseite",
    "is_favorite": "In den Favoriten",
    "add_favorite": "Zu Favoriten hinzufügen",
    "wordlimit_reached": "Wortlimit erreicht",
    "wordlimit_increase": "Wortlimit jetzt erhöhen",
    "contact": "Kontakt öffnen",
    "history": "Verlauf",
    "show_history": "Verlauf anzeigen",
    "inprint": "Impressum",
    "dataregulation": "Datenschutz",
    "tos": "AGB",
    "month": "Monat",
    "cancel_anytime": "Jederzeit kündbar",
    "try_now": "kostenlos ausprobieren",
    "book_again": "Erneut buchen",
    "book": "Plan ändern",
    "upgrade_to": "Upgrade zu {product}",
    "downgrade_to": "Downgrade zu {product}",
    "copied_link": "Link kopiert",
    "subject": "Betreff",
    "generated_by_hesse": "Von Hesse in 3 Sekunden generiert.",
    "use_template": "Template verwenden",
    "show_mode": "Mehr anzeigen",
    "upload": "hochladen",
    "optional": "optional",
    "add_source": "Quelle hinzufügen",
    "select_source": "Quelle auswählen",
    "my_documents": "Meine Dokumente",
    "my_sources": "Meine Quellen",
    "my_projects": "Meine Projekte",
    "page": "Seite {page}",
    "pages": "Seiten",
    "unknown_author": "Unbekannter Autor",
    "checker_results": "AI Detektor Ergebnisse",
    "human": "menschlich",
    "citation_style": "Zitierstil",
    "started_at": "gestartet am",
    "ends_at": "endet am",
    "cancel_subscription": "jetzt kündigen",
    "file_too_large": "Datei zu groß! (maximal 50 MB)",
    "relevance": "Relevanz",
    "relevance_help": "Relevanz misst wie gut die Quelle auf das gesuchte Thema inhaltlich passt.",
    "quality": "Qualität",
    "quality_help": "Qualität misst wie gut die Quelle inhaltlich und formal ist.",
    "trustworthiness": "Vertrauenswürdigkeit",
    "trustworthiness_help": "Vertrauenswürdigkeit misst, vor allem anhand von Metadaten der Quelle, wie verlässlich diese ist.",
    "high": "hoch",
    "normal": "normal",
    "low": "niedrig",
    "default": "aktiv",
    "features": "Funktionen",
    "monthly": "Monatlich",
    "year": "Jahr",
    "yearly": "Jährlich",
    "save_20": "spare 20%",
    "pay_amount_per_period": "Zahle {amount}/{period}.",
    "focus_of_the_chapter": "Fokus des Kapitels",
    "select_a_project": "Wähle ein Projekt",
    "no_projects": "Noch keine Projekte vorhanden"
  },
  "blog": {
    "written_by": "Geschrieben von",
    "read_article": "Artikel lesen"
  },
  "source": {
    "select": {
      "empty": {
        "headline": "Hier ist noch nichts.",
        "hint": "Du kannst Quellen in 'Quellen finden' markieren und sie in verschiedenen Tools verwenden."
      }
    },
    "table": {
      "name": "Name",
      "type": "Type",
      "created_at": "Erstellt am"
    },
    "type": {
      "document": "Dokument",
      "article": "Artikel",
      "paper": "Paper"
    },
    "snippet": {
      "hint": "Es kann sein dass der Text aus dem Originaldokument seltsam formatiert ist. In den allermeisten Fällen ist das kein Problem für die AI. Wir arbeiten aber daran das zu verbessern."
    },
    "hint": {
      "not_usable": "Quellen die noch nicht verarbeitet sind, werden beim schreiben nicht berücksichtigt."
    },
    "processing": "wird verarbeitet...",
    "recent": "zuletzt verwendet",
    "bookmark": "merken",
    "bookmarked": "gemerkt",
    "direct_pdf_access": "Direkter PDF-Zugriff",
    "source_info": "Quelleninfo"
  },
  "placeholders": {
    "url": "https://en.wikipedia.org/wiki/Radiocarbon_dating",
    "topic": "Reformen nach Ende des Zweiten Weltkriegs",
    "focus_of_the_chapter_legacy": "Politische Reformen",
    "focus_of_the_chapter": "Wie würdest du dein Kapitel betiteln?"
  },
  "groups": {
    "research": "Research",
    "essay": "Essay",
    "misc_tools": "Sonstige Tools"
  },
  "flows": {
    "summarize": {
      "headline": "Zusammenfassen",
      "description": "Was möchtest du zusammenfassen?"
    },
    "titles": {
      "summarize_document": "Dokument",
      "summarize_text": "Text",
      "summarize_article": "Artikel",
      "summarize_source": "Quelle"
    },
    "prefill": {
      "summarize_document": "Fasse mir den Inhalt dieses Dokuments zusammenfassen."
    }
  },
  "messages": {
    "get_more_words": "Jetzt mehr Wörter holen",
    "contact_success": "Vielen Dank für Ihre Nachricht!",
    "error": "Etwas hat nicht funktioniert. Bitte versuch es später noch einmal."
  },
  "validations": {
    "required": "{_field_} ist ein Pflichtfeld",
    "min": "{_field_} muss mindestens {length} Zeichen lang sein",
    "max": "{_field_} darf nicht länger als {length} Zeichen sein",
    "maxarray": "{_field_} hat zu viele Elemente",
    "url": "Keine gültige URL."
  },
  "typewriter": {
    "writeEssay": [
      "Texte werden generiert...",
      "Texte werden verschlüsselt...",
      "Plagiatcheck...",
      "Überprüfe KI Checker Tools...",
      "Texte nichtmehr nachvollziehbar ✔"
    ],
    "writeOutline": [
      "Texte werden generiert...",
      "Texte werden verschlüsselt...",
      "Plagiatcheck...",
      "Überprüfe KI Checker Tools...",
      "Texte nichtmehr nachvollziehbar ✔"
    ],
    "writeIntroduction": [
      "Texte werden generiert...",
      "Texte werden verschlüsselt...",
      "Plagiatcheck...",
      "Überprüfe KI Checker Tools...",
      "Texte nichtmehr nachvollziehbar ✔"
    ]
  },
  "domains": {
    "try": {
      "headline": "Hesse Pro",
      "loading": "Wir schalten dir dein Testkonto frei..."
    },
    "openai": {
      "status": {
        "unreliable": "Wir haben momentan erhöhte Serverlast. Es kann zu Ausfällen kommen. Wenn etwas nicht funktioniert, versuche es bitte später noch einmal.",
        "working_on_fix": "Es kann aktuell zu Ausfällen kommen. Wir arbeiten bereits an einer Lösung. Bitte versuche es später noch einmal."
      }
    },
    "ai_checker": {
      "hint": "AI Dektoren sind Tools, die Texte auf AI-Inhalt überprüfen. Mit einem Klick auf eine der Karten, kommst du zum entsprechenden Tool."
    },
    "cookie": {
      "headline": "Cookies 🍪",
      "text1": "Wenn Sie auf „Alle Cookies akzeptieren“ klicken, stimmen Sie der Speicherung von Cookies auf Ihrem Gerät zu, um die Websitenavigation zu verbessern, die Websitenutzung zu analysieren und unsere Marketingbemühungen zu unterstützen.",
      "analytics": "Leistungs Cookies",
      "analytics_text": "Diese Cookies erlauben uns, anonymisiert das Nutzerverhalten auf Hesse.ai analysieren um das Benutzererlebnis zu optimieren",
      "marketing_text": "Diese Cookies können über unsere Website von unseren Werbepartnern gesetzt werden. Sie können von diesen Unternehmen verwendet werden, um ein Profil Ihrer Interessen zu erstellen und Ihnen relevante Anzeigen auf anderen Websites zu zeigen.",
      "marketing": "Marketing Cookies",
      "technical": "Funktionelle Cookies",
      "technical_text": "Diese Cookies sind nötig um eine sichere und zuverlässige Nutzung zu gewährleisten.",
      "consent": "Alle Cookies Akzeptieren",
      "save": "Auswahl übernehmen",
      "decline": "Ablehnen"
    },
    "write": {
      "confirm_delete_paragraph": "Absatz wirklich löschen?",
      "save_changes": "Änderungen speichern",
      "keep_writing": "Weiter schreiben"
    },
    "feedback": {
      "title": "Gib uns zusätzliches Feedback",
      "description": "Wir versuchen permanent unsere Ergebnisse zu verbessern. Bitte beschreibe uns, was du geändert haben möchtest."
    },
    "report": {
      "title": "Diese Antwort wirklich melden?",
      "reason": "Grund der Meldung",
      "description": "Melde Ergebnisse, die kritische oder illegale Inhalte beinhalten.",
      "result_good": "Ergebnis ist hilfreich",
      "result_bad": "Ergebnis ist nicht hilfreich"
    },
    "pricing": {
      "monthly_wordlimit": "{limit} Wörter pro Monat",
      "increase_limit": "Limit erhöhen",
      "wordlimit_reached": "Du hast dein Wortlimit aufgebraucht",
      "wordlimit_reached_description": "Das Kontingent für diesen Monat ist ausgeschöpft. Wenn du weiter machen möchtest, musst du dein Limit anheben.",
      "wordlimit_nearly_reached": "Deine Wörter sind bald aufgebraucht.",
      "get_more_words": "Hol dir mehr Wörter",
      "get_more_words_description": "Sehr gute Texte brauchen sehr viel Rechenleistung. Das ist teuer.<br /> Aber wir haben unsere Pakete möglichst günstig gemacht.",
      "error_title": "Die Buchung hat leider nicht geklappt.",
      "error_description": "Kontaktiere uns - Wir werden unverzüglich helfen.",
      "text1": "Wie viel sind 10.000 Wörter?",
      "text2": "Das waren 10.000 Wörter",
      "referral_title": "Du kannst dich noch nicht entscheiden?",
      "referral_title2": "Mehr Wörter gefällig?",
      "referral_description": "Teile deinen Link mit Freunden. Du erhältst 1.000 Freiwörter für jede neue Anmeldung über deinen Link."
    },
    "survey": {
      "title": "Wie bewertest du Hesse.ai?",
      "suggestions": "Was würdest du verändern?",
      "success_title": "Viele Dank für dein Feedback!",
      "success_text": "Du hilfst uns, Hesse.ai kontinuierlich zu verbessern",
      "abort": "nicht bewerten"
    },
    "cards_hint": {
      "title": "Karteikarten sind jetzt verfügbar",
      "subtitle": "Automatisch <strong>Karteikarten</strong> aus deiner <strong>Vorlesung</strong> erstellen?",
      "cta": "Jetzt Karteikarten erstellen"
    },
    "share_modal": {
      "headline": "Hesse teilen - kostenlose Wörter bekommen"
    },
    "login_page": {
      "loading": "Du wirst eingeloggt...",
      "error": "Der Login hat nicht funktioniert",
      "delayed": "Der Login dauert länger als gewöhnlich.<br /> Bitte prüfe deine Internetverbindung und probiere es gegebenenfalls erneut.",
      "try_again": "Erneut einloggen"
    },
    "sidebar": {
      "create_own_application": "Eigene Anwendung erstellen",
      "hesse_cards": "Hesse Cards 🃏",
      "all_applications": "Alle Anwendungen",
      "favourites": "Meine Favoriten"
    },
    "profile": {
      "title": "Profileinstellungen",
      "limit": "Limit",
      "used_tokens": "Verwendete Tokens",
      "get_more_words": "Jetzt mehr Wörter holen",
      "no_subscription": "Du hast noch kein Abonnement.",
      "upgrade_now": "Jetzt upgraden",
      "manage_subscription": "Abonnement verwalten",
      "delete_account": "Account löschen",
      "deleted": "Dein Account wurde gelöscht. Wenn du dich einloggst, wird ein neuer Account erstellt.",
      "error_cancelling_subscription": "Es trat ein Fehler beim Kündigen auf. Bitte kontaktiere unseren Support."
    },
    "contact": {
      "title": "Kontakt",
      "subtitle": "Kontaktiere uns"
    },
    "error_page": {
      "title404": "404 - Seite nicht gefunden",
      "title": "Ein Fehler ist aufgetreten",
      "text": "Hilf mit, Hesse.ai zu verbessern.<br /> Beschreibe kurz, was du gemacht hast, unmittelbar bevor diese Seite erschienen ist.",
      "cta": "Zur Startseite"
    },
    "success_page": {
      "loading": "Wir schreiben deine Wörter gut...",
      "booking_success": "Deine Buchung war erfolgreich!",
      "booked_product": "Du hast {productName} gebucht",
      "start_now": "Jetzt durchstarten"
    }
  },
  "project": {
    "labels": {
      "incomplete": "unvollständig",
      "project": "Projekt",
      "enable_projects": "Projekte aktivieren"
    },
    "create": {
      "label": "Neues Projekt erstellen",
      "buttons": {
        "finish": "fertigstellen",
        "next": "weiter"
      },
      "welcome": {
        "title": "Willkommen bei Projekten",
        "description": "Projekte sind eine Möglichkeit, Ihre Recherche und Ihr Schreiben zu organisieren. Durch das Erstellen eines Projekts können Sie den Kontext Ihres Schreibens konsistent halten und Texte schneller erstellen.",
        "cta": "Bereit anzufangen?",
        "button": "Erstes Projekt erstellen"
      },
      "error": {
        "title": "Fehler",
        "leave": "verlassen",
        "retry": "erneut versuchen"
      },
      "steps": {
        "topic": {
          "enter": {
            "title": "Dein Thema",
            "description": "Gib bitte Dein Thema oder Deine Idee ein. Es können nur ein paar Worte oder ein kurzer Satz sein, der das Wesentliche dessen erfasst, worüber Du schreiben möchtest.",
            "label": "Geben Sie hier Ihr Thema ein"
          },
          "assess": {
            "title": "Dein Thema",
            "description": "Bewertung Ihres Themas",
            "feedback": "Feedback: ",
            "label": "Thema"
          }
        },
        "language": {
          "title": "Sprache",
          "description": "Wir denken, dass Sie in {language} schreiben möchten. Ist das korrekt oder möchten Sie es ändern?",
          "label": "Sprache"
        },
        "length": {
          "title": "Umfang",
          "description": "Wie viele Seiten soll deine Arbeit umfassen?",
          "labels": {
            "min": "Mindestens",
            "max": "Maximal"
          }
        },
        "approach": {
          "title": "Forschungsansatz",
          "description": "Wie möchten du deine Arbeit angehen?",
          "label": "Stil"
        }
      }
    },
    "delete": {
      "title": "Projekt löschen",
      "description": "Möchtest du dieses Projekt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
    }
  },
  "loader": [
    "Deine Eingaben werden bewertet",
    "Salz und Pfeffer hinzufügen",
    "Pedro, Pedro, Pedro",
    "Den Zauberstab schütteln",
    "An deinen Tisch bringen"
  ],
  "tools": {
    "findSources": "Quellen finden",
    "writeOutline": "Gliederung erstellen",
    "writeChapter": "Kapitel schreiben",
    "writeEssay": "Essay schreiben"
  }
}
